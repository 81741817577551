import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { collection, query, where, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import SavedLyrics from './SavedLyrics';

function MesTextes({ showToast }) {
  const [songs, setSongs] = useState([]);

  useEffect(() => {
    const fetchSongs = async () => {
      if (auth.currentUser) {
        const q = query(collection(db, "users", auth.currentUser.uid, "songs"), where("createdAt", "!=", null));
        const querySnapshot = await getDocs(q);
        const songsData = [];
        querySnapshot.forEach((document) => {
          songsData.push({ id: document.id, ...document.data() });
        });
        setSongs(songsData);
      }
    };

    fetchSongs();
  }, []);

  const handleDelete = async (id) => {
    if (auth.currentUser) {
      try {
        await deleteDoc(doc(db, "users", auth.currentUser.uid, "songs", id));
        setSongs(songs.filter(song => song.id !== id));
        showToast("Texte supprimé avec succès !");
      } catch (error) {
        console.error("Erreur lors de la suppression:", error);
        showToast("Une erreur est survenue lors de la suppression.", "error");
      }
    }
  };

  const handleUpdate = async (id, updatedLyrics) => {
    if (auth.currentUser) {
      try {
        await updateDoc(doc(db, "users", auth.currentUser.uid, "songs", id), {
          lyrics: updatedLyrics,
          updatedAt: new Date()
        });
        setSongs(songs.map(song => song.id === id ? { ...song, lyrics: updatedLyrics } : song));
        showToast("Texte mis à jour avec succès !");
      } catch (error) {
        console.error("Erreur lors de la mise à jour:", error);
        showToast("Une erreur est survenue lors de la mise à jour.", "error");
      }
    }
  };

  return (
    <div className="mes-textes">
      <h2 className="text-3xl font-bold mb-4 main-title-gradient">
        Mes Textes
      </h2>
      {songs.length === 0 ? (
        <p className="text-gray-400">Vous n'avez encore sauvegardé aucun texte.</p>
      ) : (
        <div className="space-y-4">
          {songs.map(song => (
            <div key={song.id} className="rap-card relative">
              <SavedLyrics 
                title={song.title}
                lyrics={song.lyrics} 
                onDelete={() => handleDelete(song.id)} 
                onUpdate={(newLyrics) => handleUpdate(song.id, newLyrics)}
                showToast={showToast}
                showDeleteButton={true}
                isInMesTextes={true}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default MesTextes; 