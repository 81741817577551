import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Mic, Edit3, Music } from 'lucide-react';

function Welcome({ onGetStarted }) {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex flex-col bg-gray-900 text-white">
      {/* Hero Section */}
      <div className="flex min-h-[90vh]">
        <div className="w-1/2 flex items-center justify-center p-4">
          <img 
            src="/rap-logo.png" 
            alt="RapGPT Logo" 
            className="w-full h-auto object-contain max-w-lg animate-float"
          />
        </div>
        <div className="w-1/2 flex flex-col items-center justify-center p-4">
          <h1 className="text-6xl font-bold bg-gradient-to-r from-[#E242F9] to-[#4F93F0] bg-clip-text text-transparent font-bungee mb-2">
            RAPGPT
          </h1>
          <p className="text-2xl mb-4 text-white">L'IA qui comprend vraiment le rap français</p>
          <div className="hero-offer bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl max-w-xl w-full border-2 border-[#b065ff]/30">
            <h3 className="text-2xl font-semibold mb-3 text-white text-center">Version Bêta Gratuite</h3>
            <p className="text-green-400 font-bold mt-4 text-center">Profitez gratuitement de toutes les fonctionnalités</p>
            <div className="flex justify-center">
              <button 
                onClick={onGetStarted}
                className="cta-button bg-gradient-to-r from-[#E242F9] to-[#4F93F0] text-white font-semibold py-3 px-6 rounded-full hover:opacity-90 transition-all duration-300 transform hover:scale-105 shadow-lg max-w-sm"
              >
                🎁 Accéder gratuitement à la version bêta
              </button>
            </div>
            <p className="text-green-400 font-bold mt-4 text-center">✨ Accès complet sans engagement</p>
          </div>
        </div>
      </div>

      {/* Le Problème */}
      <div className="bg-white/[0.02] py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-white">🎯 On connaît tes galères</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl">
              <h3 className="text-xl font-semibold mb-4 text-[#b065ff]">La prod qui attend</h3>
              <p>Les heures devant ton tel à galérer, les prods qui prennent la poussière, et des sorties qui se comptent sur les doigts d'une main.</p>
            </div>
            <div className="bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl">
              <h3 className="text-xl font-semibold mb-4 text-[#b065ff]">La page blanche qui tue</h3>
              <p>Pas d'inspi, pas de régularité, pas de structure. Résultat : zéro son qui frappe vraiment.</p>
            </div>
          </div>
          <p className="text-center mt-8 text-xl">La solution ? RapGPT, c'est comme avoir ton coach perso 24/7 qui ne dort jamais.</p>
        </div>
      </div>

      {/* Comment ça marche */}
      <div className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-white">Comment ça marche ?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl">
              <div className="flex justify-center mb-4">
                <Mic className="w-12 h-12 text-[#b065ff]" />
              </div>
              <h3 className="text-xl font-semibold mb-4 text-[#b065ff]">Choisis ton style</h3>
              <p>Sélectionne ton thème de référence et ton flow préféré pour générer des lyrics qui te ressemblent</p>
            </div>
            <div className="bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl">
              <div className="flex justify-center mb-4">
                <Edit3 className="w-12 h-12 text-[#b065ff]" />
              </div>
              <h3 className="text-xl font-semibold mb-4 text-[#b065ff]">Génère & Édite</h3>
              <p>Crée des punchlines uniques et personnalise-les selon ton inspiration</p>
            </div>
            <div className="bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl">
              <div className="flex justify-center mb-4">
                <Music className="w-12 h-12 text-[#b065ff]" />
              </div>
              <h3 className="text-xl font-semibold mb-4 text-[#b065ff]">Analyse technique</h3>
              <p>Optimise tes rimes et ton flow avec nos outils d'analyse avancés</p>
            </div>
          </div>
        </div>
      </div>

      {/* Ce que RapGPT fait pour toi */}
      <div className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-white">💡 Ce que RapGPT fait pour toi</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl">
              <h3 className="text-xl font-semibold mb-4 text-[#b065ff]">Crée des punchlines qui claquent direct</h3>
              <p>Plus de page blanche. T'es inspiré instantanément, H24.</p>
            </div>
            <div className="bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl">
              <h3 className="text-xl font-semibold mb-4 text-[#b065ff]">Booste ta productivité</h3>
              <p>Tu passes de 1 son tous les 6 mois à une régularité qui impressionne.</p>
            </div>
            <div className="bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl">
              <h3 className="text-xl font-semibold mb-4 text-[#b065ff]">Organise ton flow comme un pro</h3>
              <p>Plus de retards en studio avec une interface qui range tout pour toi.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Ce que tu reçois */}
      <div className="bg-white/[0.02] py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-white">⚡ Ce que tu reçois :</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl">
              <h3 className="text-xl font-semibold mb-4 text-[#b065ff]">🎯 Accès Premium Illimité</h3>
              <p className="font-bold mb-2">Valeur : 359.88€/an</p>
              <p>Punchlines illimitées, tous styles confondus. Du rap conscient à la trap.</p>
            </div>
            <div className="bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl">
              <h3 className="text-xl font-semibold mb-4 text-[#b065ff]">🎯 Assistant d'Écriture Personnalisé</h3>
              <p className="font-bold mb-2">Valeur : 199€</p>
              <p>RapGPT comprend les codes du rap FR et t'aide à trouver ton style unique.</p>
            </div>
            <div className="bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl">
              <h3 className="text-xl font-semibold mb-4 text-[#b065ff]">🎯 Studio d'Organisation Ultra-Pro</h3>
              <p className="font-bold mb-2">Valeur : 149€</p>
              <p>Structure tes textes, exporte en formats prêts pour le studio.</p>
            </div>
          </div>
          <div className="text-center mt-8">
            <p className="text-xl mb-4">Total valeur : 707€</p>
            <p className="text-2xl font-bold text-[#6085ff]">Aujourd'hui ? Seulement 7.99€/mois</p>
            <p className="text-lg">Oui, prix bloqué à vie.</p>
          </div>
        </div>
      </div>

      {/* Les Objections */}
      <div className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-white">❓ T'as des doutes ? Normal.</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl">
              <h3 className="text-xl font-semibold mb-4 text-[#b065ff]">"L'IA va tuer ma créativité"</h3>
              <p>Faux. RapGPT ne remplace pas ta plume – il l'amplifie. T'as toujours le contrôle, mais avec un coup de boost.</p>
            </div>
            <div className="bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl">
              <h3 className="text-xl font-semibold mb-4 text-[#b065ff]">"ChatGPT est gratuit, pourquoi payer ?"</h3>
              <p>ChatGPT, c'est basique. RapGPT est spécialement entraîné sur le rap français. Il capte les codes du game et parle ton langage.</p>
            </div>
            <div className="bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl">
              <h3 className="text-xl font-semibold mb-4 text-[#b065ff]">"C'est trop cher"</h3>
              <p>Pour le prix d'un menu McDo, tu débloques ton flow et ta productivité. Imagine ce que UN SEUL bon son peut te rapporter sur Spotify.</p>
            </div>
            <div className="bg-gray-800/90 backdrop-blur-sm p-6 rounded-lg shadow-xl">
              <h3 className="text-xl font-semibold mb-4 text-[#b065ff]">"C'est vraiment gratuit ?"</h3>
              <p>Oui ! Pendant la phase bêta, nous offrons un accès complet à tous les utilisateurs pour tester et améliorer notre service.</p>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Final */}
      <div className="bg-[#b065ff]/10 py-20">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8 text-white">👉 Prêt à entrer dans la cour des grands ?</h2>
          <div className="flex flex-col items-center justify-center mb-8">
            <span className="text-2xl font-bold text-green-400">Version Bêta Gratuite</span>
            <p className="text-gray-300 mt-2">Profitez de toutes les fonctionnalités gratuitement pendant la phase de lancement</p>
          </div>
          <button 
            onClick={onGetStarted}
            className="cta-button bg-gradient-to-r from-[#E242F9] to-[#4F93F0] text-white font-semibold py-3 px-6 rounded-full hover:opacity-90 transition-all duration-300 transform hover:scale-105 shadow-lg max-w-sm mx-auto"
          >
            🎁 Accéder gratuitement à la version bêta
          </button>
          <p className="text-center text-green-400 mt-4">✨ Offre exceptionnelle : Accès gratuit pendant la phase bêta</p>
        </div>
      </div>
    </div>
  );
}

export default Welcome; 