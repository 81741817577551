import React, { useState, useRef, useEffect } from 'react';

function SavedLyrics({ title, lyrics, onDelete, onUpdate, showToast, showDeleteButton = false, isInMesTextes = false }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedLyrics, setEditedLyrics] = useState(lyrics);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestion, setSuggestion] = useState('');
  const textAreaRef = useRef(null);
  const [suggestionPosition, setSuggestionPosition] = useState({ top: 0, left: 0 });
  const [selectedText, setSelectedText] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

  const handleSave = () => {
    try {
      onUpdate(editedLyrics);
      setIsEditing(false);
      showToast("Texte mis à jour avec succès !");
    } catch (error) {
      console.error("Erreur lors de la mise à jour:", error);
      showToast("Une erreur est survenue lors de la mise à jour.", "error");
    }
  };

  const handleCancel = () => {
    setEditedLyrics(lyrics);
    setIsEditing(false);
    setSuggestion('');
  };

  const handleTextChange = async (e) => {
    const newText = e.target.value;
    setEditedLyrics(newText);
    const textarea = textAreaRef.current;
    const cursorPos = textarea.selectionStart;

    if (newText.endsWith('\n')) {
      try {
        setIsLoading(true);
        const response = await fetch("https://api.fireworks.ai/inference/v1/completions", {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer fw_3Zk5yhX4fK3dS2CbpUqLqtWs"
          },
          body: JSON.stringify({
            model: "accounts/quentinskieur-eab1eb/models/rap-llama",
            max_tokens: 755,
            top_p: 0.9,
            top_k: 40,
            presence_penalty: 2,
            frequency_penalty: 2,
            temperature: 0.5,
            prompt: `### INSTRUCTION: Tu es un rappeur français talentueux. Complète la prochaine ligne de ce texte de rap: ### CONTEXT: ${newText}### RESPONSE:`
          })
        });

        if (!response.ok) throw new Error('Erreur lors de la complétion');
        
        const data = await response.json();
        const completion = data.choices[0].text.trim();
        setSuggestion(completion);

        // Calculer la position exacte du curseur en tenant compte du scroll
        const textBeforeCursor = newText.substring(0, cursorPos);
        const lines = textBeforeCursor.split('\n');
        const currentLineNumber = lines.length - 1;
        const currentLineStart = textBeforeCursor.lastIndexOf('\n') + 1;

        // Mettre à jour la position de la suggestion en tenant compte du scroll
        if (textarea) {
          const lineHeight = parseInt(getComputedStyle(textarea).lineHeight);
          setSuggestionPosition({
            top: (currentLineNumber * lineHeight) - textarea.scrollTop,
            left: ((cursorPos - currentLineStart) * 8) // 8px est une approximation de la largeur d'un caractère
          });
        }
      } catch (error) {
        console.error("Erreur lors de la complétion:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Tab' && suggestion) {
      e.preventDefault();
      const textarea = textAreaRef.current;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const before = editedLyrics.substring(0, start);
      const after = editedLyrics.substring(end);
      const updatedLyrics = before + suggestion + after;
      setEditedLyrics(updatedLyrics);
      onUpdate(updatedLyrics);
      setSuggestion('');
      
      // Repositionner le curseur à la fin de la suggestion insérée
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = start + suggestion.length;
        textarea.focus();
      }, 0);

      showToast("Suggestion ajoutée avec succès !");
    }
  };

  const handleTextSelection = () => {
    const textarea = textAreaRef.current;
    if (!textarea) return;

    const selection = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
    if (selection && selection.length > 0) {
      const selectionCoords = getSelectionCoordinates(textarea);
      setSelectedText(selection);
      setPopupPosition({
        top: selectionCoords.top - 40, // 40px au-dessus de la sélection
        left: selectionCoords.left
      });
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  };

  const getSelectionCoordinates = (textarea) => {
    const start = textarea.selectionStart;
    const textBeforeSelection = textarea.value.substring(0, start);
    const lines = textBeforeSelection.split('\n');
    const lineHeight = parseInt(getComputedStyle(textarea).lineHeight);
    const currentLineNumber = lines.length - 1;
    
    return {
      top: (currentLineNumber * lineHeight) - textarea.scrollTop,
      left: (textarea.selectionEnd - textarea.selectionStart) * 8 + textarea.scrollLeft
    };
  };

  const handleRewriteSelection = async () => {
    try {
      setIsLoading(true);
      const response = await fetch("https://api.fireworks.ai/inference/v1/completions", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer fw_3Zk5yhX4fK3dS2CbpUqLqtWs"
        },
        body: JSON.stringify({
          model: "accounts/quentinskieur-eab1eb/models/rap-llama",
          max_tokens: 755,
          top_p: 0.9,
          top_k: 40,
          presence_penalty: 2,
          frequency_penalty: 2,
          temperature: 0.5,
          prompt: `### INSTRUCTION: Réécris cette ligne de rap d'une manière différente mais avec le même sens: ### CONTEXT: ${selectedText}### RESPONSE:`
        })
      });

      if (!response.ok) throw new Error('Erreur lors de la réécriture');
      
      const data = await response.json();
      const newText = data.choices[0].text.trim();
      
      const textarea = textAreaRef.current;
      const before = editedLyrics.substring(0, textarea.selectionStart);
      const after = editedLyrics.substring(textarea.selectionEnd);
      const updatedLyrics = before + newText + after;
      
      setEditedLyrics(updatedLyrics);
      setShowPopup(false);
      showToast("Texte réécrit avec succès !");
    } catch (error) {
      console.error("Erreur lors de la réécriture:", error);
      showToast("Une erreur est survenue lors de la réécriture.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const textarea = textAreaRef.current;
    if (!textarea) return;

    const handleScroll = () => {
      if (suggestion) {
        const cursorPos = textarea.selectionStart;
        const textBeforeCursor = editedLyrics.substring(0, cursorPos);
        const lines = textBeforeCursor.split('\n');
        const currentLineNumber = lines.length - 1;
        const lineHeight = parseInt(getComputedStyle(textarea).lineHeight);
        
        setSuggestionPosition(prev => ({
          ...prev,
          top: (currentLineNumber * lineHeight) - textarea.scrollTop
        }));
      }
    };

    textarea.addEventListener('scroll', handleScroll);
    return () => textarea.removeEventListener('scroll', handleScroll);
  }, [suggestion, editedLyrics]);

  return (
    <div className={`saved-lyrics ${isInMesTextes ? 'in-mes-textes' : ''}`}>
      {isEditing ? (
        <div className="relative">
          <div className="textarea-wrapper">
            <textarea
              ref={textAreaRef}
              value={editedLyrics}
              onChange={handleTextChange}
              onKeyDown={handleKeyDown}
              onMouseUp={handleTextSelection}
              onKeyUp={handleTextSelection}
              rows={isInMesTextes ? 20 : 10}
              className={`w-full h-full ${isInMesTextes ? 'expanded-textarea' : ''}`}
            />
            {suggestion && (
              <div 
                className="suggestion-text"
                style={{
                  position: 'absolute',
                  top: `${suggestionPosition.top}px`,
                  left: `${suggestionPosition.left}px`
                }}
              >
                {suggestion}
              </div>
            )}
          </div>
          <div className="flex space-x-2 mt-2">
            <button onClick={handleSave} className="rap-button bg-green-600 hover:bg-green-700 flex-1">
              {isLoading ? 'Sauvegarde...' : 'Sauvegarder'}
            </button>
            <button onClick={handleCancel} className="rap-button bg-red-600 hover:bg-red-700 flex-1">
              Annuler
            </button>
          </div>
        </div>
      ) : (
        <div>
          <h3 className="text-xl font-bold mb-2 main-title-gradient">{title}</h3>
          <pre className="rap-lyrics mb-2">{lyrics}</pre>
          <div className="flex justify-end space-x-2">
            <button
              onClick={() => setIsEditing(true)}
              className="rap-button bg-blue-600 hover:bg-blue-700"
            >
              Éditer
            </button>
            {showDeleteButton && (
              <button
                onClick={onDelete}
                className="supprimer-fragment"
                aria-label="Supprimer"
              >
                ✖
              </button>
            )}
          </div>
        </div>
      )}
      {showPopup && (
        <div 
          className="rewrite-popup"
          style={{
            position: 'absolute',
            top: `${popupPosition.top}px`,
            left: `${popupPosition.left}px`,
          }}
        >
          <button 
            onClick={handleRewriteSelection}
            className="rap-button bg-purple-600 hover:bg-purple-700 text-sm py-1 px-2"
            disabled={isLoading}
          >
            {isLoading ? '...' : '✨ Réécrire'}
          </button>
        </div>
      )}
    </div>
  );
}

export default SavedLyrics;
